import React, { useState } from "react";
import parse from "html-react-parser"
import LazyLoad from 'react-lazy-load';
import Layout from "@components/layout"

// 言語用Jsonファイル
import langEn from "@content/locales/en/blog-post.json";
import langJa from "@content/locales/ja/blog-post.json";

// 共通Link用ファイル
import { LinkSetting } from "@components/link-setting.js";
import { SeoContents } from "@components/seo-data.js" // SEO,meta情報設定
import { GraphqlArchivesArray } from "@components/graphQL-archivesData.js";
import { GraphqlBannerArray } from "@components/graphQL-bannerData.js" // banner設定

const BlogPost = ({pageContext}) => {

  // アーカイブ基本設定
  let categUri = pageContext.selectType; // URLになっている部分

  // 言語別設定
  const setLang = pageContext.selectLang;
  let postLang, langUrl, setLangArray;
  if ( setLang === 'en' ) {
    postLang = langEn;
    langUrl = '/';
    setLangArray = 'enName'
  } else {
    postLang = langJa;
    langUrl = '/' + setLang + '/';
    setLangArray = 'jaName'
  }

  // Seo情報設定
  const seoTag = SeoContents( 
    categUri, setLang, 
    parse(pageContext.data.title), 
    pageContext.seo.metaDesc !== ''? pageContext.seo.metaDesc: parse(pageContext.data.excerpt)[0].props.children[0],
    postLang[ pageContext.selectType + "-page-keywords"],
    "post", pageContext.slug 
  );

  // リンク設定
  const signPath1 = LinkSetting( setLang ).Register;

  // ブログ別の翻訳変数設定
  let title, txt1, txt2 ; 
  title = postLang[pageContext.selectType + "-page-title"];
  txt1 = postLang[pageContext.selectType + "-txt1"];
  txt2 = postLang[pageContext.selectType + "-txt2"];

  // カテゴリ情報取得
  const [getData] = useState(() => GraphqlArchivesArray('post-page', pageContext.category, setLang));
  // console.log(getData)

  // ページインデックス番号
  const pageNum = getData.allData.findIndex(item => item.node.slug === pageContext.slug);

  // バナー情報取得
  const [pageBannerData] = useState(() => GraphqlBannerArray("page-banner", setLang));

  // 日時形式変換用function
  function formatDate(dateString, lang) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const day = date.getDate();
    return lang === 'ja'? `${year}年${monthIndex + 1}月${day}日`: dateString;
  }
  
  return (
  <>
    {seoTag}
    <Layout>
      <article className="blog-post" itemScope itemType="http://schema.org/Article" langType={setLang}>
        <div className="FV pt-5 pb-8 bg-fixed">
          <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2">
            <div className="text-left h1_filed">
              <p className="md:text-[60px] text-[35px] font-extrabold">{title}</p>
              <div className="text-base max-w-[740px]">
                <p>{txt1}{txt2}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-5xl mx-auto pt-5  md:pr-6 flex md:flex-nowrap flex-wrap md:justify-between justify-center">
          <div className="max-w-[740px] w-full px-6 mt-5">
            
            {/* カテゴリ画像 */}
            <div className="pb-4 relative pb-[38%] ">
              <div style={{ backgroundColor: getData.dataArray.categorycolor, color: getData.dataArray.categorytextcolor}} 
                   className="absolute z-10 text-[13px] md:top-[22%] top-[15%] left-[2%] text-center px-2 py-[3px] font-semibold">{getData.dataArray[`${setLang}categoryname`]}</div>
              <div className="absolute z-10 bg-white max-h-[100px] top-1/2 py-2 px-5 md:mt-[-25px] mt-[-10px] md:w-[80%] w-[90%]"> 
                <h1 className="tracking-wide md:text-[22px] text-[13px] font-semibold lg:text-[21px] md:leading-9 text-black" itemProp="headline">{parse(pageContext.data.title)}</h1>
              </div>
              <LazyLoad style={{ maxWidth: 740 }} className='test1 w-full my-0 absolute overflow-hidden aspect-[5/2]' offset={300}>
                <img className='test2 w-full' alt={getData.dataArray[`${setLang}categoryname`]} src={getData.dataArray.categoryimage}/>
              </LazyLoad>
            </div>

            <div className="max-w-[740px]">
              
              {/* 日付 */}
              <div className="text-[11px] py-4">
                <span className="pr-5">作成日：{formatDate(pageContext.data.date, setLang)}</span>
                <span>更新日：{formatDate(pageContext.data.modified, setLang)}</span>
              </div>        
            
              {/* パンくず */}
              <div className="flex flex-wrap text-[14px]">
                <div className='flex aligin-center mr-2'>
                  <a href={`${langUrl}${categUri}/all/`} className='py-2 mr-2 hover:text-[#E71E18]'>{title}</a>
                  <span className='py-2'>＞</span>
                </div>
                <div className='flex aligin-center mr-2'>
                  <a href={`${langUrl}${categUri}/${pageContext.category}/`} className='py-2 mr-2 hover:text-[#E71E18]'>
                    {pageContext.categoryArray.filter(item => item.slug === pageContext.category)[0][setLangArray]}
                  </a>
                  <span className='py-2'>＞</span>
                </div>
                <div className='py-2'>{parse(pageContext.data.title)}</div>
              </div>

              {/* 本文 */}
              <div className="px-0">
                <div className="md:my-0 md:mx-auto" style={{ paddingTop: 24, paddingBottom: 24, maxWidth: 976}}>
                  {!!pageContext.data.content && (
                      <section itemProp="articleBody">{parse(pageContext.data.content)}</section>
                  )}
                </div>
              </div>
            </div>

            {/* ページネーション */}
            <div className="text-center px-5 text-[11px] md:text-sm mt-12">
              <a href={`${langUrl}${categUri}/${pageContext.category}/`} className="">{postLang["post-archivePage"]}</a>
            </div>
            <div className='mt-[30px] flex justify-center'>
              {getData.allData.map((result, index) => 
                <>
                  {(index + 1 === 1 || index + 1 === getData.allData.length || Math.abs(pageNum - index) <= 2)? 
                    <div className={`${pageNum === index? 'bg-[#047DFB]': 'bg-[#D2D2D2]'} h-[36px] min-w-[36px] mx-[4%] hover:bg-[#047DFB] transition-all duration-500 `}>
                      <a className='h-full w-full text-white flex items-center justify-center' href={`${langUrl}blog/${result.node.slug}/`}>
                        {index + 1}
                      </a>
                    </div>
                    :
                    Math.abs(pageNum - index) === 3?
                      <p className='flex justify-center items-center text-[9px]'>・・・</p>
                      :
                      <></>
                  }
                </>  
              )}
            </div>

            {/* バナー画像 */}
            <p className=" md:mb-5 mb-10  mt-[10%]">
              <a href={pageBannerData[0].url} className="page_bn_link1 transition duration-300 ease-out hover:opacity-[0.6]">
                <LazyLoad className='' offset={300}><img className="" alt={pageBannerData[0].class} src={pageBannerData[0].pcimage}/></LazyLoad>
              </a>
            </p>
          </div>
                
          {/* ２カラム カテゴリフィルター */}
          <div className="md:max-w-[250px] w-full mt-5 md:px-0 px-6 ">

            {/* サイドバナー画像 */}
            <p className="md:mb-5 mb-10">
              {pageBannerData.map((result, index) =>
                <a href={result.url} className={`transition duration-300 ease-out hover:opacity-[0.6] ${index === 0? 'hidden': ''}`}>
                  <LazyLoad className='' offset={300}><img className="mb-3 page_bn_link2" alt={result.class} src={result.pcimage}/></LazyLoad>
                </a>
              )}
            </p>

            {/* カテゴリ一覧 */}
            <h3 className="bg-[#067CFA] text-white text-center px-6 py-3 mb-5 font-semibold">{postLang["blog-page-txt3"]}</h3>
            {getData.categoryArray.map((result) => 
              <div className={`min-w-[60px] mb-3 md:min-w-[36px] py-3 md:py-0 cat_btn bg-white hover:bg-[#F7F7F7] border-[0.5px] border-[#55554C] hover:border-[#F7F7F7] relative`}>
                <span style={{ backgroundColor: result.color}} className={`w-[2px] h-[70%] top-[15%] absolute left-2 block`}></span>
                <a href={result.url} className='h-full w-full flex items-center py-3 justify-center px-2'>
                  {result.name}　＞
                </a>
              </div>
            )}
            
            {/* 最新記事 */}
            <h3 className="mt-8 bg-[#067CFA] text-white text-center px-6 py-3 mb-5 font-semibold">{postLang["blog-page-txt4"]}</h3>
            <ol className="md:max-w-[700px] mx-auto">
              <div className="mx-auto max-w-[740px] pb-4 md:flex max-w-xl md:flex-wrap md:justify-start items-center">
                {getData.latestData.map((result) =>
                  <li className="">
                    <a href={`${langUrl}blog/${result.node.slug}/`} className="">
                      {/* 画像部分 */}
                      <div className="relative w-full overflow-hidden aspect-[4/1.3]">
                        <p style={{ backgroundColor: result.node.archiveImage.categorycolor, color: result.node.archiveImage.categorytextcolor}} 
                           className='absolute px-2 text-[11px] font-semibold py-[5px] bottom-0'>{result.node.archiveImage[`${setLang}categoryname`]}</p>
                        <LazyLoad className='' offset={300}>
                          <img className="" alt={result.node.archiveImage.categoryslug} src={result.node.archiveImage.categoryimage}/>
                        </LazyLoad>
                      </div>
                      {/* タイトル部分 */}
                      <div className="pb-2">
                        <p className="pt-2 py-4 font-semibold text-sm">{parse(result.node.title)}</p>
                        <p className="text-[10px] bg-gray-100 p-2">{`${postLang["blog-page-txt6"]}：${formatDate(result.node.modified, setLang)}`}</p>
                      </div>
                    </a>
                  </li>
                )}
              </div>
            </ol>

            {/* 人気の記事 */}
            <h3 className=" bg-[#067CFA] text-white text-center px-6 py-3 mb-5 font-semibold">{postLang["blog-page-txt5"]}</h3>
            <ol className="md:max-w-[700px] mx-auto">
              <div className="mx-auto max-w-[740px] pb-4 md:flex max-w-xl md:flex-wrap md:justify-start items-center">
                {getData.PopularData.map((result) =>
                  <li className="">
                    <a href={`${langUrl}blog/${result.node.slug}/`} className="">
                      {/* 画像部分 */}
                      <div className="relative w-full  overflow-hidden aspect-[4/1.3]">
                        <p style={{ backgroundColor: result.node.archiveImage.categorycolor, color: result.node.archiveImage.categorytextcolor}} 
                           className='absolute px-2 text-[11px] font-semibold py-[5px] bottom-0'>{result.node.archiveImage[`${setLang}categoryname`]}</p>
                        <LazyLoad className='' offset={300}>
                          <img className="" alt={result.node.archiveImage.categoryslug} src={result.node.archiveImage.categoryimage}/>
                        </LazyLoad>
                      </div>
                      {/* タイトル部分 */}
                      <div className="pb-2">
                        <p className="pt-2 py-4 font-semibold text-sm">{parse(result.node.title)}</p>
                        <p className="text-[10px]  bg-gray-100 p-2">{`${postLang["blog-page-txt6"]}：${formatDate(result.node.modified, setLang)}`}</p>
                      </div>
                    </a>
                  </li>
                )}
              </div>
            </ol>
            
          </div>
        </div> 
      </article>    
      <div className="Feature pt-5 mt-10 bg-fixed">
        {/* 口座開設ボタン */}
        <div className="mt-5 py-14 py-14 md:py-10 md:py-10">
          <div className="md:mx-auto max-w-5xl flex justify-center">
            <a href={signPath1} className="rounded block md:max-w-xs mx-5 text-center text-white font-bold px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300">{postLang["open"]}</a>
          </div>
        </div>
      </div>
    </Layout>
  </>
  )
}
export default BlogPost